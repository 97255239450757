import React from "react"
import Layout from "../../components/Layout"
import styles from "../../styles/contact.module.scss"

export default function Contact() {
  return (
    <>
      <Layout>
        <h1>Contact</h1>
        <form 
          name="contact" 
          method="POST" 
          data-netlify="true"
          action="/"
          className={styles.contactform}>
          {/* You still need to add the hidden input with the form name to your JSX form */}
                <input type="hidden" name="form-name" value="contact" />
                <p>
                  <label htmlFor="name">Your Name</label>
                  <input type="text" id="name" name="name" />   
                </p>
                <p>
                  <label htmlFor="email">Your Email</label>
                  <input type="email" id="email" name="email" />
                </p>
                <p>
                  <label htmlFor="message">Message</label> 
                  <textarea name="message" rows="6" id="message"></textarea>
                </p>
                <p>
                  <button type="submit">Send</button>
                </p>
              </form>
      </Layout>
    </>
  )
};